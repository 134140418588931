import { Action } from 'redux';
import { RootState } from './rootReducer';
import { getInitialFormFieldsState } from 'src/components/forms/common/initialization';
import { clone, merge } from 'lodash';
import { getFieldsFromView } from 'src/mappers/common-mapper-functions';
import { validateForm, getFormGroupWithErrors, applyFieldRules } from 'src/components/forms/common/validation';
import { FieldRule, FieldRuleType, FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import { FormValuesType } from 'src/components/forms/form-elements/FormElement.model';
import { groupBy } from 'lodash';
import { formatFieldValues, localToUTC } from 'src/utils/date-expression';

export enum inspectorFormsAction {
  INIT_INSPECTOR_FORM = 'INIT_INSPECTOR_FORM',
  SET_DEFAULT_INSPECTOR_VALUES = 'SET_DEFAULT_INSPECTOR_VALUES',
  RESET_INSPECTOR_FORM = 'RESET_INSPECTOR_FORM',
  VALIDATE_INSPECTOR_FORM = 'VALIDATE_INSPECTOR_FORM',
  // Migration
  SET_INSPECTOR_FORM_EDIT = 'SET_INSPECTOR_FORM_EDIT',
  ON_INSPECTOR_FORM_CHANGE_FIELD = 'ON_INSPECTOR_FORM_CHANGE_FIELD',
  SAVE_INSPECTOR_FORM = 'SAVE_INSPECTOR_FORM',
  INSPECTOR_FORM_DISCARD_CHANGES = 'INSPECTOR_FORM_DISCARD_CHANGES',
  INSPECTOR_FORM_CLOSE = 'INSPECTOR_FORM_CLOSE',
  RESET_CHANGED_FIELD_FOR_FILED_RULES = 'RESET_CHANGED_FIELD_FOR_FILED_RULES',
}
export interface inspectorForm {
  formValues: any;
  formErrors: { errors: { [key: string]: any }; valid: boolean };
  formFields: FormFieldsType | any;
  isEdit?: boolean;
  formGroups?: any;
  formChanges?: any;
}
export interface inspectorFormData {
  isEdit: boolean;
  formErrors: any;
  initErrors: any;
  hasChanged: boolean;
  formChanges: object;
  formGroups: { [key: string]: inspectorForm };
  initialFormGroup: object;
  // private state
  _dbSavePending: object;
  isFormDirty: boolean;
  glideObject: object;
}
export interface inspectorFormAction extends Action {
  payload: any;
  type: inspectorFormsAction;
  fieldRules?: object;
  defaultValues?: object;
  mapFieldRulesFn?: (formdata: FormData, fieldRules: object) => FormData;
}

export interface InspectorFormDiscardActionPayload {
  displayInspectorConfirmationDialog: Function;
  hideInspector: boolean;
  isGlobalViewInspectorFooter: boolean;
}

export const formInitialState: inspectorFormData = {
  formErrors: {},
  initErrors: {},
  formChanges: {},
  hasChanged: false,
  isEdit: false,
  formGroups: {},
  _dbSavePending: {},
  initialFormGroup: {},
  isFormDirty: false,
  glideObject: {},
};

export interface inspectorFormState {
  uri: any;
}

const inspectorState: inspectorFormState = {
  uri: {},
};

export const getNextFormGroupState = ({
  formGroupsState,
  enableEdit,
}: {
  formGroupsState: { [key: string]: FormValuesType };
  enableEdit: boolean;
}) => {
  const nextFormGroupsState = Object.entries(formGroupsState).reduce(
    (formGroupsAcc, [formGroup, values]) => ({
      ...formGroupsAcc,
      [formGroup]: {
        ...formGroupsState[formGroup],
        formErrors: {},
        formFields: Object.entries(values.formFields).reduce(
          (formFieldsAcc: any, field: any) => ({
            ...formFieldsAcc,
            [field[0]]: {
              ...field[1],
              disabled: !enableEdit,
            },
          }),
          {},
        ),
        hasChanged: false,
      },
    }),
    {},
  );

  return nextFormGroupsState;
};
export const inspectorForm = (state: inspectorFormState = inspectorState, action: inspectorFormAction) => {
  switch (action.type) {
    case inspectorFormsAction.INIT_INSPECTOR_FORM: {
      const newState = clone(state);
      const { glideObject, clientViewUri } = action.payload;
      const uriInfo = clientViewUri ? clientViewUri : glideObject.uri;
      newState.uri[uriInfo] = {
        formValues: {},
        formErrors: { errors: {}, valid: true },
        formFields: {},
        isEdit: false,
        formGroups: {},
        formChanges: {},
        isFormDirty: false,
        initErrors: { errors: {}, valid: true },
      };
      const fieldsObj: { [key: string]: any } = {};
      const fieldRules: any = [];
      glideObject?.data &&
        Object.entries(glideObject.data).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, glideObject?.fieldRules, uriInfo, {}, key);
          merge(fieldsObj, fields);
        }, {});
      const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObj, isEditing: false });
      glideObject?.fieldRules &&
        Object.entries(glideObject.fieldRules).map((each: any) => {
          const [, value] = each;
          value.map((eachFieldRule: any) => {
            if (eachFieldRule.field_rule_run_on_open && eachFieldRule.enabled) fieldRules.push(eachFieldRule);
          });
        });
      const fieldRuleObject = applyFieldRules({
        fieldName: '',
        fieldRules: fieldRules,
        formValues: objectFormValues.formValues,
        isEdit: false,
        objectFormField: fieldsObj,
      });
      const final_fields = Object.keys(fieldRuleObject?.finalFields);
      Object.keys(fieldsObj).map((key: any) => {
        if (final_fields.includes(key) || final_fields.includes(`fields/${key}`)) {
          const fieldKey = Object.keys(fieldRuleObject?.finalFields[`fields/${key}`])[0];
          fieldsObj[key] = {
            ...fieldsObj[key],
            [fieldKey]: fieldRuleObject?.finalFields[`fields/${key}`][fieldKey],
            disabled: true,
          };
        }
      });
      const nextFormErrors = validateForm(
        objectFormValues.formValues,
        Object.keys(fieldsObj).length ? fieldsObj : newState?.uri[uriInfo]?.formFields,
      );
      merge(newState.uri, {
        [uriInfo as string]: {
          isEdit: false,
          hasChanged: false,
          formFields: fieldsObj,
          formValues: objectFormValues.formValues,
          formErrors: {},
          initErrors: nextFormErrors,
          isFormDirty: false,
          glideObject: glideObject,
        },
      });
      return newState;
    }

    case inspectorFormsAction.SAVE_INSPECTOR_FORM: {
      const { clientViewUri: clientViewUriPayload } = action.payload;
      const newState = clone(state);
      const clientViewUri = clientViewUriPayload ? clientViewUriPayload : localStorage.getItem('last_view_selected');
      if (!clientViewUri || !state.uri[clientViewUri].isEdit) return state;
      const nextFormErrors = validateForm(
        newState?.uri[clientViewUri].formValues,
        newState?.uri[clientViewUri].formFields,
      );
      const hasErrors = Object.keys(nextFormErrors?.errors).length;
      if (hasErrors) {
        console.warn('form error, saving cancelled', nextFormErrors);
        merge(newState.uri[clientViewUri], { initErrors: { errors: {} }, formErrors: nextFormErrors, isEdit: true });
      } else {
        Object.keys(newState?.uri[clientViewUri].formFields as any).map((key: any) => {
          newState.uri[clientViewUri].formFields[key] = {
            ...newState.uri[clientViewUri].formFields[key],
            disabled: true,
          };
          if (
            newState?.uri[clientViewUri].formFields[key]?.fdddisplay &&
            newState?.uri[clientViewUri].formFields[key]?.isEdited
          ) {
            newState.uri[clientViewUri].formChanges[key] =
              newState.uri[clientViewUri].formChanges[key].replace(/,/g, '') /
              newState.uri[clientViewUri].formFields[key]?.fdddisplay;
          }
          if (
            newState?.uri[clientViewUri].formFields[key]?.isScheduleUTC &&
            newState?.uri[clientViewUri].formFields[key]?.isEdited
          ) {
            newState.uri[clientViewUri].formChanges[key] = localToUTC(newState.uri[clientViewUri].formChanges[key]);
          }
          if (newState?.uri[clientViewUri].formFields[key]?.isEdited) {
            newState.uri[clientViewUri].formFields[key].isEdited = false;
          }
        });
        merge(newState.uri[clientViewUri], {
          isEdit: false,
          hasChanged: false,
          _dbSavePending: true,
          isFormDirty: false,
        });
      }

      return newState;
    }

    case inspectorFormsAction.SET_INSPECTOR_FORM_EDIT: {
      const newState = clone(state);
      const { toggleState, clientViewUri: clientViewUriPayload } = action.payload;
      const clientViewUri = clientViewUriPayload ? clientViewUriPayload : localStorage.getItem('last_view_selected');
      const glideObjects = newState.uri[clientViewUri].glideObject;
      const newEditState = toggleState !== undefined ? toggleState : true;
      const fieldObject: { [key: string]: any } = {};
      glideObjects.data &&
        Object.entries(glideObjects.data).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, glideObjects?.fieldRules, clientViewUri, {}, key);
          merge(fieldObject, fields);
        }, {});
      Object.values(fieldObject).map((value: any) => {
        if (value.format.includes('p')) {
          const formatValue = value.format[value.format.length - 1];
          value.defaultValue = (value.defaultValue * 100).toFixed(formatValue);
        }
      });
      const objectFormValues = getInitialFormFieldsState({ formFields: fieldObject, isEditing: false });

      const fieldRules: any = [];
      glideObjects.fieldRules &&
        Object.entries(glideObjects.fieldRules).map((each: any) => {
          const [, value] = each;
          value.map((eachFieldRule: any) => {
            if (eachFieldRule.field_rule_run_on_open && eachFieldRule.enabled) fieldRules.push(eachFieldRule);
          });
        });

      //Execute field rules on edit mode of inspector
      const fieldRuleObject = applyFieldRules({
        fieldName: '',
        fieldRules: fieldRules,
        formValues: objectFormValues.formValues,
        isEdit: false,
        objectFormField: fieldObject,
        onEdit: true,
      });
      const fields = Object.keys(fieldRuleObject?.finalFields);
      const formattedValue = formatFieldValues(fieldRuleObject?.finalValues, objectFormValues.formValues);
      const objectValues = Object.fromEntries(formattedValue);
      const values = Object.keys(fieldRuleObject?.finalValues);
      Object.keys(fieldObject).map((each: any) => {
        const eachWithField = 'fields/' + each;
        if (fields.includes(eachWithField)) {
          const fieldKey = fieldRuleObject?.finalFields[eachWithField];
          fieldObject[each] = {
            ...fieldObject[each],
            ...fieldKey,
          };
        }
      });
      if (toggleState === true) {
        Object.keys(objectFormValues.formValues).map((key: any) => {
          if (values.includes(key) || values.includes(`fields/${key}`)) {
            objectFormValues.formValues[key] = fieldRuleObject?.finalValues[`fields/${key}`];
          }
        });
        const nextFormErrors = validateForm(
          objectFormValues.formValues,
          Object.keys(fieldObject).length ? fieldObject : newState?.uri[clientViewUri]?.formFields,
        );

        merge(newState.uri, {
          [clientViewUri as string]: {
            isEdit: false,
            hasChanged: false,
            formFields: fieldObject,
            formValues: objectFormValues.formValues,
            formChanges: objectValues,
            initErrors: { errors: {} },
            formErrors: nextFormErrors,
            isFormDirty: false,
          },
        });
      }

      if (newEditState) {
        const nextFormGroupsState = getNextFormGroupState({
          formGroupsState: state.uri[clientViewUri].formGroups,
          enableEdit: newEditState,
        });

        Object.keys(newState?.uri[clientViewUri].formFields as any).map((key: any) => {
          newState.uri[clientViewUri].formFields[key] = {
            ...newState.uri[clientViewUri].formFields[key],
            disabled: !newEditState,
          };
        });

        const formGroupWithErrors = getFormGroupWithErrors(nextFormGroupsState);
        merge(newState.uri[clientViewUri].formGroups, formGroupWithErrors);
        merge(newState.uri[clientViewUri].formFields, newState.uri[clientViewUri].formFields);
        merge(newState.uri[clientViewUri], { isEdit: true, hasChanged: false });
        newState.uri[clientViewUri].initErrors = { errors: {} };
        return newState;
      } else {
        Object.keys(newState?.uri[clientViewUri].formFields as any).map((key: any) => {
          newState.uri[clientViewUri].formFields[key] = {
            ...newState.uri[clientViewUri].formFields[key],
            disabled: true,
          };
        });
        // Reset form
        const fieldGroups = groupBy(newState?.uri[clientViewUri].formFields, (field: any) => field.groupName);
        Object.entries(fieldGroups).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, {}, '', {}, key);
          merge(fieldObject, fields);
        }, {});
        Object.values(fieldObject).map((value: any) => {
          if (value.format.includes('p')) {
            value.defaultValue = value.defaultValue / 100;
          }
        });
        const objectFormValues = getInitialFormFieldsState({ formFields: fieldObject, isEditing: false });
        merge(newState.uri[clientViewUri], {
          formValues: objectFormValues.formValues,
          formFields: fieldObject,
          formChanges: {},
          hasChanged: false,
          isEdit: false,
          formErrors: {},
          initErrors: { errors: {} },
          isFormDirty: false,
        });

        return newState;
      }
    }

    case inspectorFormsAction.ON_INSPECTOR_FORM_CHANGE_FIELD: {
      const { fieldUpdate, clientViewUri: clientViewUriPayload } = action.payload;
      const clientViewUri = clientViewUriPayload ? clientViewUriPayload : localStorage.getItem('last_view_selected');
      if (!clientViewUri || !state.uri[clientViewUri].isEdit) return { ...state };
      const updatedFormValues = { ...state?.uri[clientViewUri].formValues, ...fieldUpdate };

      const fieldUpdateKey = Object.keys(fieldUpdate)[0];
      let fieldUpdatedWithKey = 'fields/' + fieldUpdateKey;
      const childFieldToRerunFieldRules = [fieldUpdatedWithKey];
      let updatedValues: any = {};
      for (let childField = 0; childField < childFieldToRerunFieldRules.length; childField++) {
        fieldUpdatedWithKey = childFieldToRerunFieldRules[childField];

        Object.keys(state?.uri[clientViewUri]?.formFields as any).map((formfieldKey: any) => {
          const fieldRules = state?.uri[clientViewUri]?.formFields[formfieldKey]?.fieldRules;
          //Execute field rules on each field
          const fieldRulesToRun: FieldRule[] = [];
          // Include rules where the changed field is in the inputs,
          // but is not the target field unless the RuleType is Valid (a field can invalidate itself e.g.when checking pattern, format or length)
          // Sometimes its necessary to have the target field in the inputs where the rule needs the
          // previous value of that field, but if it changes it shouldn't trigger running the rule.
          if (fieldRules) {
            fieldRules.forEach((fieldRule: FieldRule) => {
              const inputFields = fieldRule?.field_rule_inputs;
              const targetFields = fieldRule?.field_rule_target
                ? [fieldRule?.field_rule_target]
                : fieldRule?.field_rule_targets;
              if (
                inputFields.some((inputItem: string) => inputItem === fieldUpdatedWithKey) &&
                (!targetFields.some((targetItem: string) => targetItem === fieldUpdatedWithKey) ||
                  fieldRule.ruleType === FieldRuleType.Valid)
              ) {
                fieldRulesToRun.push(fieldRule);
              }
            });
          }

          let fieldRuleObject: any = {};
          //Execute field rules on each field
          if (fieldRulesToRun.length > 0) {
            fieldRuleObject = applyFieldRules({
              fieldName: fieldUpdateKey,
              fieldRules: fieldRulesToRun,
              formValues: updatedFormValues,
              objectFormField: state.uri[clientViewUri].formFields,
              isEdit: true,
            });
            const fields = Object.keys(fieldRuleObject?.finalFields);
            const values = Object.keys(fieldRuleObject?.finalValues);
            const formattedValue = formatFieldValues(fieldRuleObject?.finalValues, updatedFormValues);
            const objectValues = Object.fromEntries(formattedValue);
            updatedValues = { ...updatedValues, ...objectValues };

            Object.keys(state?.uri[clientViewUri].formFields).map((key: any) => {
              const eachKeyWithField = 'fields/' + key;
              if (fields.includes(eachKeyWithField)) {
                const fieldKey = Object.keys(fieldRuleObject?.finalFields[eachKeyWithField]);
                fieldKey?.map((eachKey: string) => {
                  state.uri[clientViewUri].formFields[key] = {
                    ...state?.uri[clientViewUri].formFields[key],
                    [eachKey]: fieldRuleObject?.finalFields[eachKeyWithField][eachKey],
                  };
                });
              }
            });
            Object.keys(state?.uri[clientViewUri].formValues as any).map((key: any) => {
              if (values.includes(key)) {
                state.uri[clientViewUri].formValues[key] = fieldRuleObject?.finalValues[key];
              }
            });

            Object.keys(updatedFormValues as any).map((x: any) => {
              const fieldName = 'fields/' + x;
              if (values.includes(fieldName) && updatedFormValues[x] !== fieldRuleObject?.finalValues[fieldName]) {
                updatedFormValues[x] = fieldRuleObject?.finalValues[fieldName];
                if (!childFieldToRerunFieldRules.some((item: any) => item === fieldName)) {
                  childFieldToRerunFieldRules.push(fieldName);
                }
              }
            });
          }
        });
      }

      //Validate each field
      const formErrors = validateForm(updatedFormValues, state?.uri[clientViewUri].formFields);
      return {
        ...state,
        uri: {
          ...state.uri,
          [clientViewUri]: {
            ...state.uri[clientViewUri],
            formValues: { ...updatedFormValues },
            formFields: { ...state.uri[clientViewUri].formFields },
            formChanges: { ...state.uri[clientViewUri].formChanges, ...updatedValues, ...fieldUpdate },
            fieldChangeForFieldRule: Object.keys({ ...fieldUpdate }),
            hasChanged: true,
            formErrors: { ...state.uri[clientViewUri].formErrors, ...formErrors },
            isFormDirty: true,
            initErrors: { errors: {} },
          },
        },
      };
    }

    case inspectorFormsAction.RESET_INSPECTOR_FORM: {
      const newState = clone(state);
      const { clientViewUri } = action.payload;
      const uriInfo = clientViewUri;
      newState.uri[uriInfo] = {
        formValues: {},
        formErrors: { errors: {}, valid: true },
        formFields: {},
        isEdit: false,
        formGroups: {},
        formChanges: {},
        fieldChangeForFieldRule: [],
        initErrors: { errors: {} },
        isFormDirty: false,
        hasChanged: false,
      };
      return newState;
    }
    case inspectorFormsAction.RESET_CHANGED_FIELD_FOR_FILED_RULES: {
      const newState = clone(state);
      const { clientViewUri } = action.payload;
      const uriInfo = clientViewUri;
      newState.uri[uriInfo] = {
        ...newState.uri[uriInfo],
        fieldChangeForFieldRule: [],
      };
      return newState;
    }
    default:
      return state;
  }
};

export const setInspectorForm = (glideObject: object, clientViewUri?: string) => ({
  type: inspectorFormsAction.INIT_INSPECTOR_FORM,
  payload: { glideObject, clientViewUri },
});

export const saveInspectorForm = (clientViewUri: string | undefined) => ({
  type: inspectorFormsAction.SAVE_INSPECTOR_FORM,
  payload: { clientViewUri },
});

export const changeInspectorFormField = (
  fieldUpdate: any,
  formGroupName: string,
  field?: any,
  clientViewUri?: string,
) => ({
  type: inspectorFormsAction.ON_INSPECTOR_FORM_CHANGE_FIELD,
  payload: { fieldUpdate, formGroupName, field, clientViewUri },
});

export const setInspectorFormEdit = (toggleState: boolean, clientViewUri?: string) => ({
  type: inspectorFormsAction.SET_INSPECTOR_FORM_EDIT,
  payload: { toggleState, clientViewUri },
});

export const resetInspectorForm = (clientViewUri?: string) => ({
  type: inspectorFormsAction.RESET_INSPECTOR_FORM,
  payload: { clientViewUri },
});

export const discardInspectorFormChanges = (
  displayConfirmationDialog: Function,
  hideInspector: boolean,
  isGlobalViewInspectorFooter: boolean,
) => ({
  type: inspectorFormsAction.INSPECTOR_FORM_DISCARD_CHANGES,
  payload: {
    displayInspectorConfirmationDialog: displayConfirmationDialog,
    hideInspector,
    isGlobalViewInspectorFooter,
  },
});

export const closeInspectorForm = (hideInspector: boolean) => ({
  type: inspectorFormsAction.INSPECTOR_FORM_CLOSE,
  payload: { hideInspector },
});

export const selectInspectorForm = (state: RootState): inspectorFormState => state.inspectorForm;

export const selectCurrentForm = (state: RootState): inspectorForm => {
  const cvcUri = state?.tabs?.activeViewUri;
  return state?.inspectorForm?.uri[cvcUri];
};

export const ResetChangedFieldforFieldRules = (clientViewUri: string) => ({
  type: inspectorFormsAction.RESET_CHANGED_FIELD_FOR_FILED_RULES,
  payload: { clientViewUri },
});

export const selectGlobalForm = (state: RootState): inspectorForm => {
  return state?.inspectorForm?.uri['global'];
};
