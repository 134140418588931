import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { PageProps } from '@virtus/components/page/Page/Page';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ViewBlotter } from 'src/api/queries';
import ActionForm from 'src/components/forms/action-form/action-form';
import {
  ClientViewConfiguration,
  ClientViewConfigurationData,
  ClientViewTypeLookUps,
  GlideViewProps,
  GlideViewReduxDispatch,
  GlideViewReduxProps,
  ViewComponent,
} from 'src/components/glide-view/glide-view.model';
import DxPivotGrid from 'src/components/grids/dx-pivot-grid/dx-pivot-grid';
import DxGridClientView from 'src/components/grids/dxgrid-client-view/dxgrid-client-view';
import ScreenWrapper from 'src/components/screen-wrapper/screen-wrapper';
import { RootState } from 'src/reducers';
import { selectComponents, selectViewComponent } from 'src/reducers/components';
import { activeTabSelector, bottomPanelSelector, selectClientViewBottomPanel, selectCVC } from 'src/reducers/tabs';
import { dispatchActions } from 'src/app/store';
import { fetchClientViewAction, fieldNameValue } from 'src/sagas/glide-view.saga';
import BottomPanel from 'src/components/bottom-panel/bottom-panel';
import * as S from './glide-view.style';
import SummaryPanel from 'src/components/summary-panel/summary-panel';
import { NotificationsAction, hypoTestResultDetailsSelector } from 'src/reducers/notifications';
import { TestResultsOverlay } from 'src/components/test-results-overlay/test-results-overlay';
import config from 'src/config';
import { glideQuerySelectorBottomPanel, isPendingQuerySelector } from 'src/api/query';
import DataGrid from 'devextreme-react/data-grid';
import { Dispatch } from 'redux';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
// import { useWhyDidYouUpdate } from 'src/utils/common';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
// import ModalInspector from '../inspectors/glide-object-inspector/modal-inspector';
import OrderSelectionPanel from 'src/components/bottom-panel/order-bottom-panel/order-selection-panel';
import { GlideObjectSummary } from 'src/models/glide/glideObject';
import { selectedFundURIs } from 'src/reducers/portfolio';

const LazyDashboard = React.lazy(() => import('src/components/dashboard/dashboard'));

export const GlideView: React.FunctionComponent<GlideViewProps> = ({
  clientViewUri,
  path,
  isPending,
  clientViewConfiguration,
  clientViewData,
  components,
  fetchClientView,
  hypoTestResultDetails,
  bottomPanelResults,
  isLoadingBottomPanelResults,
  currentInspectorForm,
  orderBottomPanel,
  fundURIs,
  resetBottompanel,
}) => {
  if (!clientViewUri) return null;
  // const [instanceUri, setInstanceUri] = useState<string>('');
  const dataGridRef = useRef<DataGrid>(null);
  const pivotGridRef = useRef(null);
  const bottomPanelGridRef = useRef(null);
  const isInspectorFormDirty = useRef(undefined);
  const dispatch = useDispatch();
  // const setInstanceUriHandler = useCallback((uri: string) => {
  //   setInstanceUri(uri);
  // }, []);

  const isOrderBottomPanelExpanded =
    components?.viewComponents?.[clientViewUri as string]?.['bottomPanel']?.['isVisible'];

  const pageToolBarProps = {};
  const isBottomPanelExpanded =
    components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'];
  const [showTestResults, setShowTestResults] = useState(false);
  const onCloseTestResults = (_event?: any, reason?: any) => {
    if (reason === backDropClick) return;
    setShowTestResults(false);
    dispatch({
      type: NotificationsAction.RESET_TEST_RESULTS,
    });
  };
  const [isMainGridRef, setIsMainGridRef] = useState(true);

  useEffect(() => {
    // Incorrect: the hypo test result should be rendered separately
    // This has nothing to do with the view
    if (hypoTestResultDetails) {
      setShowTestResults(hypoTestResultDetails);
    } else {
      setShowTestResults(false);
    }
  }, [hypoTestResultDetails]);

  const pageProps: Partial<PageProps> = {
    pageTitle: clientViewConfiguration.display_name,
    appName: 'Glide',
    testid: clientViewConfiguration.uri,
  };

  const onSettingsClick = (dataGridRef: any) => {
    if (dataGridRef) {
      const uri = clientViewConfiguration?.uri;
      setIsMainGridRef(dataGridRef.current?.props?.id === uri);
    }
    dispatchActions.components.toggleDisplay('layoutManager');
  };

  const bottomPanelFields = () => {
    if (!orderBottomPanel) return [];
    return orderBottomPanel.map((field: GlideObjectSummary) => ({
      text: field.display_name,
      title: field.display_name,
      disabled: false,
      key: field.uri?.lastSplitValue(),
      uri: field.uri,
    }));
  };

  const orderBottomPanelContent = orderBottomPanel?.length ? (
    <OrderSelectionPanel bottomPanelFields={bottomPanelFields()} selectedRowData={{}} clientViewUri={clientViewUri} />
  ) : null;

  const orderBottomPanelContentMemo = useMemo(() => orderBottomPanelContent, [clientViewUri, orderBottomPanel?.length]);

  useEffect(() => {
    isInspectorFormDirty.current = currentInspectorForm?.isFormDirty;
  }, [currentInspectorForm?.isFormDirty]);

  // TODO: extract and test with an action dispatch in glide-view.saga
  const refreshGrid = () => {
    fetchClientView({ clientViewUri, isManualRefresh: true });
    dispatchActions.components.updateView('gridLayout', clientViewUri, {
      hasChanges: false,
    });
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: refreshGrid,
    ...CONFIRMATION_DIALOG,
  });

  // TODO: extract and test with an action dispatch in glide-view.saga
  const onRefresh = () => {
    if (isInspectorFormDirty.current) {
      displayConfirmationDialog();
    } else {
      refreshGrid();
      resetBottompanel(clientViewUri);
    }
  };

  const pendingLoader = (
    <Loading
      show
      full
      type={LoadingIconType.Glide}
      size={LoadingIconSizes.extraLarge}
      text={`Loading ${clientViewConfiguration.display_name}...`}
      style={{ height: 'calc(100% - 88px)' }}
    />
  );

  const viewComponentRender = (renderProps: {
    uri: string;
    dataSource: any;
    actionsCollection?: any;
    gridRef?: any;
    gridTitle?: string;
    gridDescription?: string;
    isBottomPanel?: boolean;
    clientViewConfiguration: ClientViewConfigurationData;
  }): ViewComponent => {
    return {
      [ClientViewTypeLookUps.Grid]: (
        <DxGridClientView
          clientViewConfiguration={renderProps?.clientViewConfiguration}
          data-testid={`data-grid-${renderProps?.uri}`}
          dataSource={renderProps?.dataSource}
          actionsCollection={renderProps?.actionsCollection}
          dataGridRef={renderProps?.gridRef}
          enableRowDragging={false}
          onRefresh={onRefresh}
          onSettingsClick={onSettingsClick}
          headerText={{ title: renderProps?.gridTitle, description: renderProps?.gridDescription }}
          storageKey={`data-grid-${renderProps?.uri}`}
          scrollingProps={{ rowRenderingMode: 'virtual', showScrollbar: 'always', useNative: true }}
          // setInstanceUri={setInstanceUriHandler}
        />
      ),
      [ClientViewTypeLookUps.Pivot]: (
        <DxPivotGrid
          enableStorage
          storageKey={`pivot-grid-${renderProps?.uri}`}
          clientViewConfiguration={clientViewConfiguration}
          refreshHandler={onRefresh}
          pivotGridData={renderProps?.dataSource}
          onSettingsClick={onSettingsClick}
          showAllFieldsColumn
          showFieldChooser={false}
          ref={pivotGridRef}
        />
      ),
      [ClientViewTypeLookUps.Dashboard]: (
        <Suspense fallback={pendingLoader}>
          <LazyDashboard
            endpoint={`${config.glide.DASHBOARD_URL}`}
            initialDashboardId={clientViewUri.lastSplitValue()}
          />
        </Suspense>
      ),
      //custom component is a pivot grid with custom SummaryPanel
      [ClientViewTypeLookUps.Custom]: (
        <DxPivotGrid
          enableStorage
          storageKey={`pivot-grid-${renderProps?.uri}`}
          clientViewConfiguration={clientViewConfiguration}
          refreshHandler={onRefresh}
          pivotGridData={renderProps?.dataSource}
          onSettingsClick={onSettingsClick}
          showAllFieldsColumn
          showFieldChooser={false}
          ref={pivotGridRef}
          showRowGrandTotals={false}
          showColGrandTotals={false}
        />
      ),
    };
  };

  const getMainRegionContent = useMemo(() => {
    if (
      !components.global.isGomOpen &&
      (isPending || !clientViewData) &&
      clientViewConfiguration.client_view_type !== ClientViewTypeLookUps.Dashboard &&
      ((clientViewUri?.lastSplitValue() === 'compliance_dashboard' && fundURIs?.length > 0) ||
        clientViewUri?.lastSplitValue() !== 'compliance_dashboard')
    ) {
      return pendingLoader;
    }
    if (!(clientViewConfiguration.client_view_type in ClientViewTypeLookUps)) {
      console.warn(
        `[GlideView] client_view_type ${clientViewConfiguration.client_view_type} not implemented (${clientViewUri}}`,
      );
      return null;
    }

    return (
      <S.ViewContentWrapper>
        {
          viewComponentRender({
            uri: clientViewUri,
            dataSource: clientViewData,
            actionsCollection: clientViewConfiguration?.actions_collection,
            gridRef: dataGridRef,
            clientViewConfiguration: clientViewConfiguration,
          })[clientViewConfiguration.client_view_type]
        }
      </S.ViewContentWrapper>
    );
  }, [
    isPending,
    clientViewUri,
    clientViewData,
    clientViewConfiguration.client_view_type,
    clientViewConfiguration?.actions_collection,
    isLoadingBottomPanelResults,
    bottomPanelResults,
    fundURIs,
  ]);

  const instanceSensitiveInfo: ClientViewConfiguration[] =
    components.viewComponents[clientViewUri]?.bottomPanel?.instanceSensitiveObject;
  const getBottomRegionContent = useMemo(() => {
    const loader = isLoadingBottomPanelResults ? (
      <Loading show type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} style={{ height: '40vh' }} />
    ) : null;
    const isBottomPanelExpanded =
      components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'];
    const bottomPanelContent =
      instanceSensitiveInfo && instanceSensitiveInfo.length > 0
        ? viewComponentRender({
            uri: instanceSensitiveInfo[0]?.uri,
            dataSource: bottomPanelResults ?? {
              data: bottomPanelResults?.data ?? [],
              schema: bottomPanelResults?.schema ?? [],
            },
            actionsCollection: instanceSensitiveInfo[0]?.data?.actions_collection,
            gridRef: bottomPanelGridRef,
            gridTitle: `Compliance Test Results for Scenario ${fieldNameValue}`,
            gridDescription: 'Select a test result to view its details',
            isBottomPanel: true,
            clientViewConfiguration: bottomPanelResults?.clientViewConfiguration,
          })[instanceSensitiveInfo[0].data.client_view_type]
        : null;
    return (
      (loader || bottomPanelContent || orderBottomPanelContentMemo) &&
      (isBottomPanelExpanded || Boolean(orderBottomPanelContentMemo)) && (
        <S.BottomPanelWrapper isBottomPanelExpanded={isBottomPanelExpanded || isOrderBottomPanelExpanded}>
          <S.Divider />
          <BottomPanel clientViewUri={clientViewUri}>
            {loader ?? bottomPanelContent ?? orderBottomPanelContentMemo}
          </BottomPanel>
        </S.BottomPanelWrapper>
      )
    );
  }, [
    isBottomPanelExpanded,
    isLoadingBottomPanelResults,
    bottomPanelResults,
    orderBottomPanelContentMemo,
    instanceSensitiveInfo,
  ]);

  const layoutRef = () => {
    if (
      instanceSensitiveInfo &&
      instanceSensitiveInfo[0]?.data?.client_view_type === ClientViewTypeLookUps.Grid &&
      !isMainGridRef
    )
      return bottomPanelGridRef;
    else if (clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Grid) return dataGridRef;
    else if (clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Pivot) return pivotGridRef;
    return null;
  };
  // Temporary, to help identify what prop to use to override the DataGrid ErrorBoundary
  /*
  useWhyDidYouUpdate(`[glide-view] ${clientViewConfiguration.uri}`, [
    isPending,
    clientViewUri,
    clientViewData,
    clientViewConfiguration.client_view_type,
    clientViewConfiguration?.actions_collection,
    isLoadingBottomPanelResults,
    bottomPanelResults,
  ]);

*/
  return (
    <>
      <QuitInspector />
      <ScreenWrapper
        pageProps={pageProps}
        pageToolBarProps={pageToolBarProps}
        path={path}
        clientViewData={isMainGridRef ? clientViewData : bottomPanelResults}
        dataRef={layoutRef()}
        // setInstanceUri={setInstanceUriHandler}
      >
        {components.actionForm?.visible && <ActionForm targetUris={components.actionForm?.actionUri} />}

        <SummaryPanel />

        {/*
      TODO: This needs to be migrated to the screen-wrapper
      Glide-view should render the client view type. Nothing else :(
*/}
        {showTestResults && hypoTestResultDetails && (
          <TestResultsOverlay
            testResultName={fieldNameValue}
            dataSource={hypoTestResultDetails}
            onCloseModal={onCloseTestResults}
          />
        )}
        <>
          {getMainRegionContent}
          {getBottomRegionContent}
        </>
      </ScreenWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState): GlideViewReduxProps => {
  const clientViewUri = activeTabSelector(state);
  const bottomPanelComponent = selectViewComponent(state, 'bottomPanel', clientViewUri);
  return {
    bottomPanelResults: glideQuerySelectorBottomPanel(state, bottomPanelComponent?.clientViewUri),
    clientViewConfiguration: selectCVC(state, clientViewUri),
    clientViewData: ViewBlotter.selectClientViewData(state),
    components: selectComponents(state),
    hypoTestResultDetails: hypoTestResultDetailsSelector(state),
    instanceSensitiveBottomPanel: selectClientViewBottomPanel(state),
    isLoadingBottomPanelResults: isPendingQuerySelector(state, bottomPanelComponent?.clientViewUri),
    isPending: ViewBlotter.isPending(state, activeTabSelector(state)),
    orderBottomPanel: bottomPanelSelector(state),
    currentInspectorForm: selectCurrentForm(state),
    fundURIs: selectedFundURIs(state, { uri: clientViewUri }),
  };
};
const mapDispatchToProps = (dispatch: Dispatch): GlideViewReduxDispatch => ({
  fetchClientView: ({ clientViewUri, isManualRefresh }) =>
    dispatch(fetchClientViewAction({ clientViewUri, isManualRefresh })),
  resetBottompanel: (viewUri: string) => {
    dispatch({
      type: 'RESET_BOTTOM_PANEL',
      payload: { clientViewUri: viewUri },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlideView);
