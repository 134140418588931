import React from 'react';
import { CustomCellRenderKeyProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from '../dxgrid-client-view.model';
import { ComplianceCellRender } from 'src/components/grids/cell-render/compliance-cell-render/compliance-cell-render';
import { ComplianceTestResult } from 'src/components/grids/cell-render/compliance-cell-render/compliance-cell-render-test-result';
import { ScenarioResultCell } from '../../hypo-scenario-grid/components/Cells';
import { DragCellRender } from './drag-cell-render/drag-cell-render';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { MarketMapColumns } from 'src/models/marketmap/marketmap.model';
// import { updateOnLoadCellValues } from 'src/utils/cell-calculation-field-rule';
// import { PriceChangeCellRender } from '@virtus/components/DxDataGrid/cellRenders/price-change-cell-render/price-change-cell-render';
import { PositionCellRender } from '@virtus/components/DxDataGrid/cellRenders/Position/PositionCellRender';
import { DisplayOverlaysCellRender } from '../../cell-render/diplay-overlays-cell-render/diplay-overlays-cell-render';
const complianceTestResultRender = {
  cellRender: (cellData: CellData) => {
    const value: string = (cellData?.value as string) ?? '';
    return <ComplianceTestResult renderValue={value} />;
  },
};
const complianceTestResultCellRender: CustomCellRenderKeyProps = {
  'Compliance Baseline Trigger': { ...complianceTestResultRender },
  'Baseline Value': { ...complianceTestResultRender },
  'Compliance Current Trigger': { ...complianceTestResultRender },
  'Current Value': { ...complianceTestResultRender },
};

const complianceCellRender = (): CustomCellRenderKeyProps => {
  return {
    'Compliance Status': {
      cellRender: (cellData: CellData) => {
        const value: string = (cellData?.value as string) ?? '';

        return <ComplianceCellRender renderValue={value} />;
      },
    },
  };
};

const positionCellRender = (): CustomCellRenderKeyProps => {
  return {
    Position: {
      cellRender: (cellData: CellData) => <PositionCellRender value={cellData.value} />,
    },
  };
};

const selectionCellRenders = (): CustomCellRenderKeyProps => {
  return {
    issuer: {
      cellRender: (cellData: any) => (
        <div>
          <span style={{ color: 'var(--default-blue)' }}>{cellData.text}</span>
        </div>
      ),
    },
    drag: {
      cellRender: (cellData: any) => <DragCellRender cellData={cellData} />,
    },
    // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
    // [MarketMapColumns.MarketMapLastPrice]: {
    //   cellRender: (cellData: CellData) => {
    //     const fieldKey = cellData?.column?.dataField.toString();
    //     const fieldInfo = dataSource.schema.filter((item: any) => item.display_name === fieldKey)[0];
    //     const fieldRulesValue = updateOnLoadCellValues(cellData, fieldInfo?.calculation_script_js, dataSource.schema);
    //     return (
    //       <PriceChangeCellRender
    //         cellData={cellData}
    //         value={fieldRulesValue ? fieldRulesValue : ''}
    //         format={fieldInfo?.format}
    //       />
    //     );
    //   },
    // },
  };
};

const currentResultCellRender: CustomCellRenderKeyProps = {
  'Current Result': {
    cellRender: (cellData: CellData) => <ScenarioResultCell cellData={cellData} />,
  },
};

const displayOverlaysCellRender: CustomCellRenderKeyProps = {
  Blog: {
    cellRender: (cellData: CellData) => <DisplayOverlaysCellRender cellData={cellData} />,
  },
  KPIs: {
    cellRender: (cellData: CellData) => <DisplayOverlaysCellRender cellData={cellData} />,
  },
  Covenants: {
    cellRender: (cellData: CellData) => <DisplayOverlaysCellRender cellData={cellData} />,
  },
};

export default {
  complianceTestResultCellRender,
  complianceCellRender,
  selectionCellRenders,
  currentResultCellRender,
  positionCellRender,
  displayOverlaysCellRender,
};
