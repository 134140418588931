import { put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { dispatchActions } from 'src/app/store';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { toggleComponentViewDisplayAction, updateComponentAction } from 'src/reducers/components';
import { activeTabSelector, selectCVC } from 'src/reducers/tabs';

export type DataGridActions =
  | 'DATAGRID_RESET'
  | 'DATAGRID_ROW_SELECTION'
  | 'DATAGRID_SET_GRID_REF'
  | 'WATCHLIST_REMOVE'
  | 'UNRESTRICT_INSTRUMENT';

export enum componentsActions {
  RESET_COLUMN_MANAGER = 'RESET_COLUMN_MANAGER',
  RESET_INSPECTOR = 'RESET_INSPECTOR',
}

export interface componentsResetPayload {
  previousUri: string;
}

const handleRowDeletion = (uri: string, cvc_uri: string) => {
  // @ts-ignore:
  const uri_index = window['gridInstances'][cvc_uri]?.current?.instance
    .getDataSource()
    .items()
    .findIndex((dxGridItem: any) => dxGridItem['Glide ID'] === uri);
  // @ts-ignore:
  window['gridInstances'][cvc_uri]?.current?.instance.deleteRow(uri_index);
  // @ts-ignore:
  window['gridInstances'][cvc_uri]?.current?.instance.saveEditData().then(_ => {
    // @ts-ignore:
    window['gridInstances'][cvc_uri]?.current?.instance.refresh();
  });
};

/**
 * 💡 All logic related to datagrid row click can be handled over here.
 */
export function* handleRowSelection(): Generator<HTMLDivElement | null | undefined, void, HTMLDivElement> {
  // ∎ Enable selectCart component when row is clicked.
  const selectCartElement = yield document.querySelector('#selectedRowAction')?.closest('.dx-item.dx-toolbar-item');
  selectCartElement && selectCartElement.classList.remove('dx-state-disabled');
  const auditSelectIcon = yield document.querySelector(
    '.vertical-separator-line.toolbar-audit-trail-btn-disable',
  ) as HTMLDivElement;
  auditSelectIcon && auditSelectIcon.classList.remove('toolbar-audit-trail-btn-disable');
}

/**
 * 💡 All logic related to datagrid reset (when glide-view component unmounts) can be handled over here.
 */
export function* handleReset(): Generator<HTMLDivElement | null | undefined | SelectEffect | PutEffect, void, any> {
  // ∎ Switching between views should disable selectCart component.
  const selectCartElement = yield document
    .querySelector('#selectedRowAction')
    ?.closest('.dx-item.dx-toolbar-item') as HTMLDivElement;
  selectCartElement && selectCartElement.classList.add('dx-state-disabled');
  const auditSelectIcon = yield document.querySelector('.vertical-separator-line') as HTMLDivElement;
  auditSelectIcon && auditSelectIcon.classList.add('toolbar-audit-trail-btn-disable');
  const cvcUri = yield select(activeTabSelector);
  yield put(
    updateComponentAction('actionForm', {
      target_uri: '',
    }),
  );
  // @ts-ignore: FIXME
  const gridInstance = window['gridInstances'][cvcUri];
  if (gridInstance) {
    gridInstance?.current?.instance?.refresh();
  }
}

export function* handleWatchListRemove(action: any): Generator<SelectEffect, void, ClientViewConfigurationData> {
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  if (clientViewConfiguration.uri === 'instance/client_view_configuration/instrument_watchlist') {
    handleRowDeletion(action.payload?.data, clientViewConfiguration.uri);
  }
}

export function* handleUnrestrictInstrument(action: any): Generator<SelectEffect, void, ClientViewConfigurationData> {
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  if (clientViewConfiguration.uri === 'instance/client_view_configuration/restricted_assets') {
    handleRowDeletion(action.payload?.data, clientViewConfiguration.uri);
  }
}

export function* handleColumnManagerReset(action: any): Generator<PutEffect, void, componentsActions> {
  yield put(toggleComponentViewDisplayAction('columnManagerInspector', action.payload.previousUri, false));
}

export function* handleInspectorReset(action: any): Generator<PutEffect, void, componentsActions> {
  yield put(dispatchActions.components.updateView('viewInspector', action.payload.previousUri, { isCollapsed: true }));
}
export function* watchDatagridRowSelection() {
  yield takeLatest('DATAGRID_ROW_SELECTION' as DataGridActions, handleRowSelection);
}

export function* watchDatagridReset() {
  yield takeLatest('DATAGRID_RESET' as DataGridActions, handleReset);
}

export function* watchWatchListRemove() {
  yield takeLatest('WATCHLIST_REMOVE' as DataGridActions, handleWatchListRemove);
}

export function* watchUnrestrictInstrument() {
  yield takeLatest('UNRESTRICT_INSTRUMENT' as DataGridActions, handleUnrestrictInstrument);
}

export function* watchColumnManagerReset() {
  yield takeLatest(componentsActions.RESET_COLUMN_MANAGER, handleColumnManagerReset);
}

export function* watchInspectorReset() {
  yield takeLatest(componentsActions.RESET_INSPECTOR, handleInspectorReset);
}

export default [
  watchDatagridRowSelection,
  watchDatagridReset,
  watchColumnManagerReset,
  watchInspectorReset,
  watchWatchListRemove,
  watchUnrestrictInstrument,
];
