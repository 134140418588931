import React, { useEffect, useRef, useState } from 'react';
import Calendar, { CalendarErrors } from '@virtus/components/Calendar';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from '../FormErrorWrapper';
import { zIndex } from 'src/utils/constants';
import * as S from './DateInput.style';
import { adjustDateFromFieldString, getDefaultDateFromConfig } from 'src/utils/date-expression';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'src/reducers';
import { DateInputSagaAction, formattedDate, getFieldValueToSet } from 'src/sagas/date-input/date-input.saga';
import { selectComponents } from 'src/reducers/components';
import { ResetChangedFieldforFieldRules, selectInspectorForm } from 'src/reducers/inspectorForm.reducer';
import { activeTabSelector } from 'src/reducers/tabs';
import {
  isModalFormEnabled,
  ResetModalChangedFieldforFieldRules,
  selectModalForm,
} from 'src/reducers/modalForm.reducer';

interface ReduxProps {
  dateStringLength: number;
  separator: string;
  changedField: string[];
  formValues: Object;
  placeholder: string;
  activeUri: string;
  isModalFormEnabled: boolean;
}

interface DateInputProps extends FormElementInputComponent {
  setInitialData: () => void;
  placeholder: string;
  separator: string;
  dateStringLength: number;
  changedField: string[];
  resetChangedFieldforFieldRules: (clientViewUri: string) => void;
  resetModalChangedFieldforFieldRules: (clientViewUri: string) => void;
  activeUri: string;
  formValues: Object;
  isModalFormEnabled: boolean;
}

const DateInput = React.memo(
  ({
    field,
    value = '',
    error,
    onChange,
    customWidth,
    formGroupChildrenStyle,
    placeholder,
    separator,
    dateStringLength,
    setInitialData,
    changedField,
    resetChangedFieldforFieldRules,
    resetModalChangedFieldforFieldRules,
    activeUri,
    formValues,
    isModalFormEnabled,
  }: DateInputProps) => {
    if (field?.hidden) return null;
    const [open, setOpen] = useState(false);
    const [dateError, setDateError] = useState(error);
    const [dateObj, setDateObj] = useState(formattedDate(new Date(value)));
    const [hasFocus, setFocus] = useState(false);
    const [isInputDirty, setIsInputDirty] = useState<Boolean>(false);
    const [newInputValue, setNewInputValue] = useState<string>('');
    const componentRef = useRef<any>();
    const dateInputRef = useRef<any>();
    const dateInputElementRef = useRef<any>();

    useEffect(() => {
      // due to complexity in diffrent date formate we are not allowing space in edit mode
      if (!(field.readonly || field.disabled)) {
        setDateObj({ ...dateObj, formattedDate: dateObj.formattedDate.replace(/ /g, '') });
      }
    }, [field]);

    useEffect(() => {
      setInitialData();
    }, []);

    useEffect(() => {
      const handler = (e: MouseEvent) => {
        if (!componentRef.current.contains(e.target)) {
          setFocus(false);
          OpenCalendar(e, false);
          if (isInputDirty) {
            setIsInputDirty(false);
            const fieldValue = getFieldValueToSet(dateObj.formattedDate, field, placeholder).fieldValue;
            setDateObj(fieldValue);
            onChange({
              target: {
                value: fieldValue.date,
                addEventListener: () => null,
                removeEventListener: () => null,
                dispatchEvent: () => true,
              },
            });
          }
        }
      };
      document.addEventListener('click', handler);
      return () => {
        document.removeEventListener('click', handler);
      };
    }, [componentRef, isInputDirty, dateObj]);
    useEffect(() => {
      const filteredFieldRule = field?.fieldRules?.find(rule => rule.ruleType === 'Value');
      if (!filteredFieldRule || !changedField?.length || !(typeof value === 'string' && value.includes('fields_')))
        return;

      const { field_rule_inputs: ruleInputs } = filteredFieldRule;

      const hasMatchingField = changedField.some(fieldName =>
        ruleInputs.some((field: string) => field.includes(fieldName)),
      );

      if (hasMatchingField) {
        const nextValue = adjustDateFromFieldString(value, formValues);
        const formattedDateObj = formattedDate(new Date(nextValue));

        setDateObj(prev => {
          const newFormattedDate = formattedDateObj.formattedDate.replace(/ /g, '');
          if (prev.formattedDate !== newFormattedDate) {
            return { ...formattedDateObj, formattedDate: newFormattedDate };
          }
          return prev; // No change, return previous state
        });
        if (isModalFormEnabled) {
          resetModalChangedFieldforFieldRules(activeUri);
        } else {
          resetChangedFieldforFieldRules(activeUri);
        }
      }
    }, [changedField]);
    useEffect(() => {
      setDateError(error);
    }, [error]);

    useEffect(() => {
      let nextValue = value;
      if (typeof value === 'string' && value.includes('today')) {
        nextValue = getDefaultDateFromConfig(value);
      } else if (typeof value === 'string' && value.includes('fields_')) {
        nextValue = adjustDateFromFieldString(value, formValues);
        // resetChangedFieldforFieldRules(clientViewUri)
      }
      const formated_date = formattedDate(new Date(nextValue));
      if (!(field.readonly || field.disabled)) {
        setDateObj({ ...formated_date, formattedDate: formated_date.formattedDate.replace(/ /g, '') });
      } else {
        setDateObj(formated_date);
      }
    }, [value]);

    const OnkeyDownHandler = (e: React.KeyboardEvent) => {
      e.stopPropagation();
      setNewInputValue(e.key);
      if (isInputDirty) return;
      setIsInputDirty(true);
    };

    const onDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const d = e.target.value;
      //  it stop user to enter char apart from backspace, valid separator, or digit
      if (
        newInputValue !== 'Backspace' &&
        newInputValue !== separator &&
        (!/^\d+$/.test(newInputValue) || d.length > dateStringLength)
      ) {
        e.preventDefault;
        return;
      }
      const FieldValueToSet = getFieldValueToSet(d, field, placeholder);
      if (d.length) setDateError(FieldValueToSet.error);
      setDateObj(prev => ({ ...prev, date: '', formattedDate: d.trim() }));
    };

    const onDateChange = (v: string) => {
      if (onChange) {
        onChange({
          target: {
            value: v,
            addEventListener: () => null,
            removeEventListener: () => null,
            dispatchEvent: () => true,
          },
        });
        setDateObj(formattedDate(new Date(v)));
      }
      dateInputRef && dateInputRef.current && dateInputRef.current.focus();
    };

    const OpenCalendar = (e?: any, open = true) => {
      e.preventDefault();
      setOpen(open);
    };

    const topProp = field.calendarAlign === 'left' ? '-35px' : '33px';
    const leftProp = field.calendarAlign === 'left' ? '-190px' : '-35px';
    const calendarWrapperStyle: { wrapper: React.CSSProperties } = {
      wrapper: {
        border: 'var(--foreground-calendar-border)',
        position: 'absolute',
        width: 'fit-content',
        backgroundColor: 'var(--background-calendar)',
        zIndex: zIndex.Calendar,
        top: topProp,
        left: leftProp,
      },
    };

    return (
      <FormErrorWrapper
        error={dateError}
        displayName={field.displayName}
        name={field.name}
        disabled={field.disabled}
        required={field.required}
        readonly={field.readonly}
        disableErrorWarning={field.disableErrorWarning}
        customWidth={customWidth}
        formGroupChildrenStyle={formGroupChildrenStyle}
      >
        <S.DateInputWrapper ref={componentRef}>
          <S.DateButton
            onClick={OpenCalendar}
            disabled={field.readonly || field.disabled}
            $error={Boolean(dateError) && !(field.readonly || field.disabled)}
            onFocus={() => setFocus(true)}
            $hasFocus={hasFocus}
            $editIsEnabled={!field.disabled && !field.readonly}
            ref={dateInputRef}
          >
            <S.DateInputElement
              data-testid={`${field.name}-calendar-input` || 'calendar-input'}
              ref={dateInputElementRef}
              onChange={onDateInputChange}
              onKeyDown={OnkeyDownHandler}
              onFocus={() => setFocus(true)}
              disabled={field.disabled || field.readonly}
              placeholder={placeholder}
              value={dateObj.date !== CalendarErrors.INVALID_DATE && dateObj.formattedDate ? dateObj.formattedDate : ''}
            />
            <S.CalendarContainer>
              <S.StyledCalendarIcon disabled={field.readonly || field.disabled} />
            </S.CalendarContainer>
          </S.DateButton>

          {open && (
            <Calendar
              style={calendarWrapperStyle}
              data-testid={`${field.name}-calendar` || 'calendar'}
              value={dateObj.date}
              onChange={onDateChange}
              setFocus={setFocus}
              openCalendar={OpenCalendar}
            />
          )}
        </S.DateInputWrapper>
      </FormErrorWrapper>
    );
  },
);

const mapStateToProps = (state: RootState): ReduxProps => {
  const components = selectComponents(state);
  const clientViewUri = activeTabSelector(state);
  const modalInspector = state.components.modalInspector;

  const ActiveModalKey = Object.entries(modalInspector).find(([, value]) => value.isVisible === true)?.[0];

  const getFormData = (isModal: boolean) => {
    const key = isModal ? ActiveModalKey : clientViewUri;
    const formSelector = isModal ? selectModalForm(state) : selectInspectorForm(state);

    return {
      changedField: formSelector?.uri?.[key]?.fieldChangeForFieldRule || [],
      formValues: formSelector?.uri?.[key]?.formValues || [],
      activeUri: key,
    };
  };

  const isModal = isModalFormEnabled(state);
  // console.log(isModal);
  const { changedField, formValues, activeUri } = getFormData(isModal);

  return {
    separator: components.dateInput.separator,
    dateStringLength: components.dateInput.dateStringLength,
    placeholder: components.dateInput.placeholder,
    changedField,
    formValues,
    isModalFormEnabled: isModal,
    activeUri,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setInitialData: () =>
    dispatch({
      type: DateInputSagaAction.SETINITIAL,
    }),
  resetChangedFieldforFieldRules: (clientViewUri: any) => dispatch(ResetChangedFieldforFieldRules(clientViewUri)),
  resetModalChangedFieldforFieldRules: (clientViewUri: any) =>
    dispatch(ResetModalChangedFieldforFieldRules(clientViewUri)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateInput);
