import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withStyles } from 'tss-react/mui';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { Search as SearchIcon } from '@mui/icons-material';

export const MenuItemStyled = withStyles(MenuItem, {
  root: {
    height: 26,
    margin: 0,
    padding: 5,
    fontSize: 'var(--form-input-font-size)',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.14,
    letterSpacing: -0.2,
    color: 'var(--foreground-form)',
    backgroundColor: 'var(--background-form-dropdown-items)',
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'inherit',
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'var(--background-form-dropdown-items-hover)',
    },
    '&:hover': {
      backgroundColor: 'var(--background-form-dropdown-items-hover)',
    },
    '& span.MuiCheckbox-root': {
      paddingRight: '5px',
    },
  },
});

export const MenuItemTextStyled = styled.div<{ disabled?: boolean }>`
  text-transform: capitalize;
  display: block;
  text-align: left;
  padding: 0 0 0 5px;
  flex: 1;
  align-items: center;
  font-size: var(--form-input-font-size);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: var(--foreground-form);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SelectStyled = styled(Select)<{
  value: any;
  classes: any;
  onChange: any;
  disabled?: boolean;
  error?: boolean;
  $editIsEnabled?: boolean;
  $hasFocus?: boolean;
  multiple?: boolean;
}>`
  border-radius: 3px;
  font-size: var(--form-input-font-size);
  font-weight: normal;
  background-color: ${props => (props.disabled ? 'transparent' : 'var(--background-form)')};
  border: ${props => {
    if (props.error) return '2px solid var(--foreground-error)';
    if ((props.$editIsEnabled && !props.$hasFocus) || (props.$editIsEnabled && props.multiple && !props.$hasFocus))
      return 'var(--foreground-form-input-edit-border)';
    if ((props.$editIsEnabled && props.$hasFocus) || (props.$editIsEnabled && props.multiple && props.$hasFocus))
      return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};
  color: var(--foreground-form);
  text-align: left;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & .MuiSelect-select:focus {
    background-color: var(--background-form);
    border: 'none';
  }
  & svg {
    font-size: 18px;
    top: calc(50% - 9px);
    right: 6px;
  }
  & .MuiSelect-select.MuiInputBase-input.MuiInput-input {
    padding: 0 0 0 5px;
    color: var(--foreground-form);
    &.Mui-disabled {
      -webkit-text-fill-color: unset;
    }
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  &:hover {
    background-color: inherit !important;
  }
  padding: 0px;
  & .MuiSvgIcon-root {
    font-size: var(--form-checkbox-icon-font-size);
  }
`;

export const MenuItemForSearch = withStyles(MenuItemStyled, {
  root: {
    height: '30px',
    paddingTop: '10px',
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
  margin-left: 2px;
  font-size: var(--search-icon-font-size);
  color: var(--disabled-grey);
`;
