import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { useMarketmap } from 'src/hooks/use-marketmap';
import { selectComponents } from 'src/reducers/components';
import { GLIDE_LABELS } from 'src/utils/labels';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { Switch } from '@virtus/components/Switch';
import Modelling from 'src/components/portfolio/components/modelling/modelling';
import ProposedOrders from 'src/components/portfolio/components/proposed-orders/proposed-orders';
import { Template } from 'devextreme-react/core/template';
import { PortfolioSagaAction } from 'src/sagas/portfolio/proposed-order.saga';
import { actions } from 'src/reducers/actions';
import { DxToolbarButton } from '@virtus/components/DxDataGrid/model/DxDataGrid.model';
import { ActionsCollectionWebDropdown } from 'src/components/portfolio/components/actions-collection-web/actions-collection-web';
import PortfolioFundSelector from 'src/components/portfolio-fund-selector/portfolio-fund-selector';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';

interface GridTemplateType {
  clientViewConfiguration: ClientViewConfigurationData;
}

export interface ToolbarButtons {
  display_name: string;
  uri: string;
}
const { portfolio_modelling } = GLIDE_LABELS;

// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// export const getLivePriceToggleStatus = (
//   marketMapToggleStatus: any,
//   cvcUri: string,
//   isLoggedInToMarketmap: boolean,
// ) => {
//   const isToggleSet = marketMapToggleStatus[cvcUri];
//   return isLoggedInToMarketmap && isToggleSet === undefined ? true : isToggleSet;
// };

const GridTemplates = ({ clientViewConfiguration }: GridTemplateType) => {
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const { toggleLivePrice } = useMarketmap();
  const dispatch = useDispatch();
  const { global } = useSelector(selectComponents);
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const { marketMapToggleStatus, isLoggedInToMarketmap } = livePriceToggleState;
  // const cvcUri = clientViewConfiguration?.uri?.lastSplitValue();
  // const marketMapToggleFlag = getLivePriceToggleStatus(marketMapToggleStatus, cvcUri!, isLoggedInToMarketmap);
  const toolbarButtonsFromConfig = [] as DxToolbarButton[];
  const toolbarTemplates = [] as any[];

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const toggleLivePriceHandler = useCallback(
  //   (_checked: boolean) => {
  //     cvcUri && toggleLivePrice(cvcUri, _checked);
  //   },
  //   [cvcUri, toggleLivePrice],
  // );

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const renderSwitchComponent = useCallback(
  //   () => <Switch onChange={toggleLivePriceHandler} checked={marketMapToggleFlag} />,
  //   [marketMapToggleFlag, toggleLivePriceHandler],
  // );

  const ModellingButtonRender = useCallback(() => {
    const onModellingClick = () => {
      const uri = 'instance/actions/fund_modeling';
      dispatch({
        type: actions.action.DISPATCH_ACTION,
        payload: { action: { uri, data: { arguments: {} } }, clientViewConfiguration },
      });
    };

    return (
      <Modelling
        onIconClick={onModellingClick}
        isDisabledIcon={!clientViewConfiguration?.toolbar_buttons?.some(v => v.includes('fund_modeling'))}
      />
    );
  }, [dispatch]);

  const getProposedOrder = () =>
    dispatch({ type: PortfolioSagaAction.FETCH_PROPOSED_ORDERS, payload: { clientViewConfiguration } });

  const renderPortfolioManager = useCallback(
    () => <PortfolioFundSelector clientViewConfiguration={clientViewConfiguration} />,
    [clientViewConfiguration?.uri],
  );
  const renderActionsCollectionWeb = useCallback(
    (type: string) => {
      let returnComponent: JSX.Element = <></>;
      switch (type) {
        case 'orderdropdown':
          returnComponent = (
            <ActionsCollectionWebDropdown
              type={type}
              rowData={global.orders.rowData}
              actionCollectionsWeb={clientViewConfiguration?.actions_collection_web}
              disableOrderDropdown={global.orders.disabled}
            />
          );
          break;
        case 'addinstruments':
          returnComponent = <ActionsCollectionWebDropdown type={type} hideBorder={true} />;
          break;
      }
      return returnComponent;
    },
    [global.orders.disabled, global.orders.rowData],
  );

  const TOOLBAR_BUTTONS_TEMPLATE = [
    {
      uri: 'instance/toolbar_buttons/portfolio_manager',
      templateName: 'portfolioManager',
      component: renderPortfolioManager,
      display_name: 'Portfolio Manager',
      toolbarItem: {
        name: 'portfolioManagerButton',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          template: 'portfolioManager',
          hint: 'Portfolio Manager',
          elementAttr: { 'data-testid': 'portfolio-manager-btn' },
        },
        location: 'before',
      },
    },
    // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
    // {
    //   uri: 'instance/toolbar_buttons/live_price',
    //   templateName: 'livePricesSwitch',
    //   // component: renderSwitchComponent,
    //   display_name: 'Live Price',
    //   toolbarItem: {
    //     name: 'switchComponent',
    //     widget: 'dxButton',
    //     options: {
    //       template: 'livePricesSwitch',
    //       hint: 'Live Prices',
    //       elementAttr: { 'data-testid': 'livePriceSwitch-btn', class: 'dx-switch' },
    //     },
    //     location: 'after',
    //   },
    // },
    {
      uri: 'instance/toolbar_buttons/proposed_orders',
      templateName: 'portfolio-proposed-order',
      // @ts-ignore
      component: () => <ProposedOrders />,
      display_name: 'Proposed Orders',
      toolbarItem: {
        name: 'portfolioProposedOrderButton',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          template: 'portfolio-proposed-order',
          hint: 'Proposed Orders',
          elementAttr: { 'data-testid': 'portfolio-proposed-order-btn' },
          onClick: getProposedOrder,
        },
        location: 'after',
      },
    },
    {
      uri: 'instance/toolbar_buttons/portfolio_order_dropdown',
      templateName: 'portfolioOrderDropdownBtn',
      component: () => renderActionsCollectionWeb('orderdropdown'),
      display_name: 'Portfolio New Order Dropdown',
      toolbarItem: {
        name: 'portfolioOrderDropdownBtn',
        widget: 'dxButton',
        options: {
          template: 'portfolioOrderDropdownBtn',
          hint: 'Portfolio Order Dropdown',
          elementAttr: { 'data-testid': 'portfolio-order-dropdown', class: 'toolbar-dropdown' },
        },
        location: 'after',
      },
    },
    {
      uri: 'instance/toolbar_buttons/portfolio_import_instrument',
      templateName: 'portfolioAddDropdownBtn',
      component: () => renderActionsCollectionWeb('addinstruments'),
      display_name: 'Portfolio Import Instrument Button',
      toolbarItem: {
        name: 'portfolioAddDropdownBtn',
        widget: 'dxButton',
        options: {
          template: 'portfolioAddDropdownBtn',
          hint: 'Add Instrument',
          elementAttr: { 'data-testid': 'portfolio-import-instrument-dropdown', class: 'toolbar-dropdown' },
        },
        location: 'after',
      },
    },
    {
      uri: 'instance/toolbar_buttons/fund_modeling',
      templateName: 'modellingTemplate',
      component: ModellingButtonRender,
      display_name: 'Modeling',
      toolbarItem: {
        name: 'modellingButton',
        location: 'after',
        widget: 'dxButton',
        options: {
          template: 'modellingTemplate',
          hint: portfolio_modelling.tooltip_text,
          elementAttr: { 'data-testid': 'portfolio-modelling-btn' },
        },
        cssClass: 'vertical-separator-line',
      },
    },
  ];

  if (clientViewConfiguration.toolbar_buttons) {
    clientViewConfiguration.toolbar_buttons.forEach(toolbarButton => {
      const toolbarButtonItem = TOOLBAR_BUTTONS_TEMPLATE.find(templateItem => templateItem.uri === toolbarButton);
      if (toolbarButtonItem) {
        toolbarTemplates?.push(
          <Template
            key={toolbarButtonItem.templateName}
            name={toolbarButtonItem.templateName}
            render={toolbarButtonItem.component}
          />,
        );
      }
      if (toolbarButtonItem?.toolbarItem) {
        toolbarButtonsFromConfig.push(toolbarButtonItem.toolbarItem);
      }
    });
  }

  return {
    toolbarButtonsFromConfig,
    toolbarTemplates,
  };
};

export default GridTemplates;
