import React, { useEffect, useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import Select from './components/Select';
import { OptionElement } from '../../FormElement.model';

const day_options: OptionElement[] = [
  {
    name: 'Sunday',
    value: '0',
  },
  {
    name: 'Monday',
    value: '1',
  },
  {
    name: 'Tuesday',
    value: '2',
  },
  {
    name: 'Wednesday',
    value: '3',
  },
  {
    name: 'Thursday',
    value: '4',
  },
  {
    name: 'Friday',
    value: '5',
  },
  {
    name: 'Saturday',
    value: '6',
  },
];

const convertObjectToString = (day_schedule: string[]) => {
  const days: Record<string, string> = {
    0: 'S',
    1: 'M',
    2: 'T',
    3: 'W',
    4: 'T',
    5: 'F',
    6: 'S',
  };

  let scheduled_days_string = '';
  if (day_schedule.length > 0) {
    for (const day in days) {
      if (day_schedule.includes(day)) {
        scheduled_days_string += days[day];
      } else {
        scheduled_days_string += '.';
      }
    }
  }
  return scheduled_days_string;
};

const convertStringToObject = (Stringvalue: string) => {
  const days: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  const value = Stringvalue.split('').reduce((acc, char, index) => {
    if (char !== '.') {
      acc.push(days[index]);
    }
    return acc;
  }, [] as string[]);

  return value;
};

export const DayScheduletInput = ({
  field,
  onChange,
  error,
  //formValues,
  customWidth,
}: Omit<FormElementInputComponent, 'value'>) => {
  if (field?.hidden) return null;
  const [optionName, setOptionName] = useState<any>([]);

  useEffect(() => {
    if (field.defaultValue) {
      setOptionName(convertStringToObject(field.defaultValue));
    }
  }, []);

  const onHandleChange = (e: any) => {
    if (e.target.value.includes('')) return;
    const fieldValues = e.target.value;
    const values: string[] = [];
    fieldValues?.map((d: any) => {
      const filteredOptions = Object.values(day_options as any).find((x: any) => x.value === d || x.name === d);
      if (filteredOptions) {
        values.push((filteredOptions as any)?.value);
      }
    });
    setOptionName(typeof fieldValues === 'string' ? fieldValues.split(',') : fieldValues);
    e.target.value = convertObjectToString(values);
    onChange && onChange(e);
  };
  const renderValues = (data: any) => {
    const values: string[] = [];
    data?.map((d: any) => {
      const filteredOptions = Object.values(day_options as any).find((x: any) => x.value === d || x.name === d);
      if (filteredOptions) {
        values.push((filteredOptions as any)?.value);
      }
    });
    return convertObjectToString(values);
  };

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      <Select
        dataTestId={`${field.name}-multi-select`}
        disabled={field.disabled || field.readonly}
        value={!optionName ? [] : optionName}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={onHandleChange}
        menuItems={day_options}
        sort_menuItems={false}
        multiple={true}
        renderValues={renderValues}
        is_search_enable={false}
        editIsEnabled={!field.disabled && !field.readonly}
      ></Select>
    </FormErrorWrapper>
  );
};
