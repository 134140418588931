import styled from 'styled-components';
import { SecondaryButton as SB, PrimaryButton as PB } from '@virtus/components/Buttons';

export const Footer = styled.div<{ disabled?: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
  min-height: 57px;
  width: 100%;
  background: var(--background-form-footer);
  padding: 0 10px;
  border-top: var(--foreground-form-border);
  border-bottom: var(--foreground-form-border);
  overflow: hidden;
  opacity: 1;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const MoreActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MoreActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const PrimaryButton = styled(PB)<{ backgroundcolor?: string }>`
  margin-left: 25px;
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-primary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 18px;

  &:hover {
    background: var(--background-form-primary-btn);
  }
  &:disabled {
    color: var(--foreground-form);
    opacity: 0.5;
    background: var(--background-form-primary-btn);
  }
`;

export const SecondaryButton = styled(SB)<{ backgroundcolor?: string }>`
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-secondary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 23px;

  &:hover {
    background: var(--background-form-secondary-btn);
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
