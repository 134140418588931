import get from 'lodash/get';
import { Reducer } from 'redux';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { User } from './msalWrapper';

export const authActions = {
  // B2C init
  initialize: createStandardAction('@auth/initialize')<any>(),
  initialized: createStandardAction('@auth/initialized')(),
  // Nexus auth actions
  login: createStandardAction('@auth/b2c/login')<any>(),
  logout: createStandardAction('@auth/b2c/logout')(),
  loginSuccess: createStandardAction('@auth/b2c/login/success')<object>(),
  loginError: createStandardAction('@auth/b2c/login/error')<string>(),
  unauthorized: createStandardAction('@auth/b2c/unauthorized')(),
  getAccessToken: createStandardAction('@auth/idp/getAccessToken')<object>(),
  validateUser: createStandardAction('@auth/idp/validateUser')<object>(),

  // Glide auth actions
  glideLogin: createStandardAction('@auth/glide/login')<any>(),
  glideLogout: createStandardAction('@auth/glide/logout')(),
  glideLoginSuccess: createStandardAction('@auth/glide/login/success')<GlideSession>(),
  refreshToken: createStandardAction('@auth/glide/refreshtoken')<AuthState>(),
  registerRefreshToken: createStandardAction('@auth/glide/refreshtoken/register')<object>(),
  glideLoginError: createStandardAction('@auth/glide/login/error')<GlideAuthError>(),
  // Portal auth actions
  portalLogin: createStandardAction('@auth/portalLogin')(),
};

export type AuthActions = ActionType<typeof authActions>;

export type AuthStatus = 'uninitialized' | 'initializing' | 'initialized' | 'logout';

export type GlideAuthError = { statusCode: number; statusMessage?: string };

//IDP AUth Error
export type IDPAuthError = { statusCode: number; statusMessage?: string };

export type GlideSession = {
  token: string;
  environment: string;
  user: any;
  username?: string;
  expiresIn: string;
  refreshToken: string;
  authorization: string;
};

//IDP Session
export type IDPSession = {
  token: string;
  environment: string;
  user: any;
  username?: string;
  expiresIn: string;
  refreshToken: string;
};

export interface GlideUser {
  name: string;
}

//IDP User
export interface IDPUser {
  name: string;
}

export type AuthState = {
  // after successful login
  readonly user: User | IDPUser | GlideUser | null;
  readonly token: string | null;
  readonly code: string | null;
  readonly isValidUser: any;
  // after failed login
  readonly error: string | null;
  readonly status: AuthStatus;
  idpSession?: IDPSession;
  glideSession: GlideSession;
  readonly glideAuthError: GlideAuthError | null;
  readonly id_token?: string;
  readonly emailAddress?: string;
};

export type RootState = { auth: AuthState };

export const initialState: AuthState = {
  error: null,
  idpSession: {} as IDPSession,
  glideSession: {} as GlideSession,
  status: 'uninitialized',
  token: null,
  code: null,
  user: null,
  isValidUser: false,
  glideAuthError: null,
  id_token: '',
  emailAddress: '',
};

export const auth: Reducer<AuthState, AuthActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(authActions.loginSuccess): {
      return {
        ...state,
        ...action.payload,
        error: null,
        status: 'initialized',
      };
    }
    case getType(authActions.loginError): {
      return {
        ...initialState,
        error: action.payload,
        status: 'initialized',
      };
    }
    case getType(authActions.getAccessToken): {
      const tmpData = {
        ...state,
        ...action.payload,
      };
      return tmpData;
    }
    case getType(authActions.validateUser): {
      const tmpData = {
        ...state,
        ...action.payload,
      };
      return tmpData;
    }
    case getType(authActions.unauthorized): {
      return {
        ...initialState,
        status: 'initialized',
      };
    }
    case getType(authActions.logout): {
      return {
        ...initialState,
        status: state.status,
      };
    }
    case getType(authActions.initialize): {
      return {
        ...state,
        status: 'initializing',
      };
    }
    case getType(authActions.initialized): {
      return {
        ...state,
        status: 'initialized',
      };
    }
    // Glide auth actions
    case getType(authActions.glideLoginError): {
      return {
        ...initialState,
        glideAuthError: action.payload,
      };
    }
    case getType(authActions.glideLoginSuccess): {
      const payload = action.payload;
      const user = JSON.parse(payload.user);
      const glideSession = {
        ...payload,
        user: user.data,
        username: payload.username?.toLowerCase(),
      };
      return {
        ...state,
        status: 'initialized',
        glideSession,
        token: glideSession.token,
        user: { name: user.data.display_name },
      };
    }
    case getType(authActions.glideLogout): {
      return {
        ...state,
        status: 'logout',
      };
    }
    default: {
      return state;
    }
  }
};

export const authSelectors = {
  isAuthenticated: (state: RootState) => !!state.auth.user,
  idpSession: (state: RootState): IDPSession => state.auth.idpSession || ({} as IDPSession),
  isGlideAuthenticated: (state: RootState) => Boolean(state.auth.glideSession.token),
  glideSession: (state: RootState): GlideSession => state.auth.glideSession,
  glideAuthError: (state: RootState) => state.auth.glideAuthError,
  getStatus: (state: RootState) => state.auth.status,
  getUser: (state: RootState) => state.auth.user,
  getToken: (state: RootState) => state.auth.token,
  getRefreshToken: (state: RootState) => state.auth.glideSession.refreshToken,
  getCode: (state: RootState) => state.auth.code,
  getUsername: (state: RootState) =>
    get(state, 'auth.user.idToken.extension_Username') || get(state, 'auth.user.name', ''),
  getUserEmails: (state: RootState): string[] =>
    get(state, 'auth.user.idToken.emails') || get(state, 'auth.user.emails', []),
  loginError: (state: RootState) => state.auth.error,
  isValidUser: (state: RootState): any => state.auth.isValidUser,
  getAccessToken: (state: RootState): string => state.auth.id_token || '',
  getEmailAddress: (state: RootState): string => state.auth.emailAddress || '',
};
