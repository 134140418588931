import { Argument } from 'src/models/order/glideActionArguments.api.model';
import { RootState } from './rootReducer';
import { GlideObject } from 'src/models/glide/glideObject';

export const actions = {
  notifications: {
    RESET: 'NOTIFICATION_RESET',
  },
  errors: {
    RESET: 'ERRORS_RESET',
  },
  action: {
    DISPATCH_ACTION: 'DISPATCH_ACTION',
    EXECUTE_ACTION: 'EXECUTE_ACTION',
    GET_INSTRUMENT_BEHAVIOUR_ACTIONS: 'GET_INSTRUMENT_BEHAVIOUR_ACTIONS',
  },
};

// Handling for action form override object
export const ACTION_FORM_OVERRIDE_ACTIONS = {
  SET_ACTION_OVERRIDE_OBJECT: 'SET_ACTION_OVERRIDE_OBJECT',
  RESET_ACTION_OVERRIDE_OBJECT: 'RESET_ACTION_OVERRIDE_OBJECT',
};

export type ActionOverrideObjInitialState = {
  [key: string]: any;
};

export type ActionFormActionType = {
  type: string;
  payload: any;
};

export const actionFormOverride = (state: ActionOverrideObjInitialState = {}, action: ActionFormActionType) => {
  switch (action.type) {
    case 'EXECUTE_ACTION':
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_FORM_OVERRIDE_ACTIONS.SET_ACTION_OVERRIDE_OBJECT:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_FORM_OVERRIDE_ACTIONS.RESET_ACTION_OVERRIDE_OBJECT:
      return {
        ...{},
      };
    default:
      return state;
  }
};

export interface ActionPayload {
  targetUri?: string[];
  action?: any;
  // LEGACY prop. All actions are expanded  should come from action collections (CVC/object). Nothing should be hardcoded!
  actionUri?: string;
  // Arguments
  actionArguments?: { [key: string]: Argument };
  date?: string[] | string;
  clientViewUri: string;
  isBottomPanel?: boolean;
}

// type ActionType = 'EXECUTE_ACTION';

// Accepts URI or display name values
type GlideColorLookup = 'lookups/blue' | 'lookups/red' | 'Blue' | 'Red';

// This is partial interface to the full object describing only fields relevant to UI
export interface GlideAction extends GlideObject {
  data: {
    display_name: string;
    actions?: string[]; // sub action button available for the action (save/cancel)
    enabled: boolean;
    category?: string;
    editor_button_style?: GlideColorLookup;
    action?: string; // action_uri used by some global action
    uri?: string;
    static_menu_action?: boolean;
    view?: string; // Action with view are handled with GOI
    arguments?: string[]; // Action with argument are handled with ActionForm
    is_cancel_action?: boolean;
    check_required_fields?: boolean;
    transition_end_value?: string;
  };
}

export interface GlideWorkflowAction extends GlideObject {
  data: {
    display_name: string;
    editor_button_style?: GlideColorLookup;
    transition_end_value: string;
    transition_start_value: string;
    workflow: string;
    show_in_edit_mode?: boolean;
    uri?: string;
  };
}

interface ActionDispatch {
  // type: ActionType;
  // TODO: add typing
  type: any;
  payload: any;
}

interface ExecuteAction {
  // Take uri from either the GlideAction object or the uri
  actionUri?: string;
  glideAction?: GlideAction;
  targetUri: string[];
  actionArguments?: object;
  date?: [];
  clientViewUri?: String;
}

// TODO: Refactor so it works with GlideAction
// @ts-ignore
export const executeActionCreator = ({
  glideAction,
  actionUri,
  targetUri,
  actionArguments,
  clientViewUri,
}: ExecuteAction): ActionDispatch => {
  const _actionUri = glideAction?.uri || actionUri || '';
  const _clientViewUri = clientViewUri || '';
  return {
    type: actions.action.DISPATCH_ACTION,
    payload: {
      action: glideAction,
      actionUri: _actionUri,
      targetUri,
      arguments: actionArguments,
      clientViewUri: _clientViewUri,
    },
  };
};

export const actionFormOverrideSelector = (state: RootState) => state.actionFormOverride || {};
