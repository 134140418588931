// import { GlideObject } from 'src/models/glide/glideObject';
import { authActions } from '@virtus/common/auth';
import { uniqBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { ClientViewConfiguration, ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import { RootState } from 'src/reducers';
import { TabsAction } from 'src/reducers/tabs';
import { getType } from 'typesafe-actions';
import sortBy from 'lodash/sortBy';
import { glideQuery } from 'src/api/query';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { MARKET_MAP } from 'src/sagas/auth-glide.saga';
import { authSelectors } from '@virtus/common/auth/reducer';
import { PortfolioActionTypes } from 'src/reducers/portfolio';

export interface GlideSearchParams {
  object_type: string;
  search_fields?: object;
  fields?: string[];
  field_display_format?: 'uri' | 'lookups_display_name' | 'display_name' | 'summary';
  expand_prop?: string[];
  expand_fields_requested?: string;
}

// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// const marketMapUris = ['instance/toolbar_buttons/live_price', 'instance/toolbar_buttons/portfolio_import_instrument'];

const getModuleRoutes = (data: any) =>
  data
    ?.map((cvc: ClientViewConfiguration) => {
      if (cvc?.data?.browser_region === 'BottomRegion') return;
      return {
        name: cvc.data.display_name,
        displayName: cvc.data.display_name,
        path: cvc.uri.substring(cvc.uri.lastIndexOf('/')),
        fullPath: cvc.uri.substring(cvc.uri.lastIndexOf('/')),
        uri: cvc.uri,
        category: cvc.data.category,
        is_default_view: cvc.data.is_default_view,
      };
    })
    .filter((routes: ClientViewConfiguration) => routes);

const getGlideViews = (routes: any) => {
  return (routes || []).reduce((acc: any, route: any) => {
    const _viewname = route?.path.replace('/', '');
    return { ...acc, [_viewname]: { displayName: route?.displayName, uri: route?.uri } };
  }, {});
};

const hasPermission = (user: any, permissions: any) => {
  if (!permissions) return true; // allow by default if no permissions are set
  return permissions.some(
    (permission: string) => !!user.roles.includes(permission.replace('instance/permissions/allow_', '')),
  );
};

export function* fetchCVCandActions(): any {
  const searchParams: GlideSearchParams = {
    object_type: 'client_view_configuration',
    expand_prop: ['actions_collection', 'actions_collection_web', 'static_menu_actions'],
  };
  const glideSession = yield select(authSelectors.glideSession);

  const clientViewConfigurationResponse: any = yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/gsearch',
        transform: (response: any) => {
          return { clientViewConfigurations: response };
        },
        options: { method: 'POST' },
        body: { ...searchParams, return_all_fields: true, expression: "enabled=true&category!=''" },
        update: {
          clientViewConfigurations: (_: any, next: any) => next,
        },
      }),
    ),
  );

  for (const clientViewConfiguration of clientViewConfigurationResponse.body) {
    const hasComplianceButtons =
      clientViewConfiguration?.data?.web_summary_panel?.panel_left?.action_buttons.length > 0;
    if (
      clientViewConfiguration.data.client_view_type === ClientViewTypeLookUps.Custom ||
      clientViewConfiguration.data.toolbar_buttons?.includes('instance/toolbar_buttons/portfolio_manager')
    ) {
      if (
        clientViewConfiguration.data.client_view_type === ClientViewTypeLookUps.Custom &&
        !clientViewConfiguration.data.is_time_series &&
        !hasComplianceButtons
      )
        continue;
      yield put({
        type: PortfolioActionTypes.SET_PORTFOLIOS,
        payload: {
          clientViewUri: clientViewConfiguration.uri,
        },
      });
    }
  }

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const isMarketMapEnabledForClient =
  //   clientViewConfigurationResponse.body.filter((item: GlideObject) =>
  //     item.data?.toolbar_buttons?.some((uri: string) => marketMapUris.includes(uri)),
  //   ).length > 0;

  // yield put({
  //   type: MARKET_MAP.ENABLE_MARKET_MAP,
  //   payload: { isMarketMapEnabled: isMarketMapEnabledForClient },
  // });

  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/gsearch',
        transform: (response: any) => {
          return { globalActions: response };
        },
        options: { method: 'POST' },
        body: { object_type: 'global_actions', return_all_fields: true },
        update: {
          globalActions: (_: any, next: any) => next,
        },
      }),
    ),
  );

  const groupedCVCData: any = groupBy(
    uniqBy(clientViewConfigurationResponse?.body, 'uri'),
    (el: ClientViewConfiguration) => el.data.category,
  );

  // @ts-ignore
  let groupedRoutes: any = Object.keys(groupedCVCData).map((category: any) => {
    const modulesViews = groupedCVCData[`${category}`];

    const allowedViews = modulesViews.filter((view: any) => hasPermission(glideSession.user, view.data?.permissions));
    const subroutes = getModuleRoutes(sortBy(allowedViews, route => route.data.display_name));

    if (subroutes.length > 0) {
      return {
        name: category,
        displayName: category,
        category: category,
        path: category.trim().replace(/\s/g, '-'),
        fullPath: category,
        uri: subroutes[0].uri,
        subroutes,
        views: getGlideViews(subroutes),
      };
    }
  });

  // initialise gridInstances stored in window as redux can't handle such large objects
  // @ts-ignore
  window['gridInstances'] = {};

  // @ts-ignore
  groupedRoutes = groupedRoutes.concat([
    // {
    //   name: 'x Credit Details',
    //   path: 'credit_details',
    //   displayName: 'x Credit Details',
    //   fullPath: '/credit_details',
    // },
    // {
    //   name: 'x Deals',
    //   displayName: 'x Deal Pipeline',
    //   category: 'x Deals',
    //   path: 'deals',
    //   fullPath: '/deals',
    // },
  ]);

  // put new module Routes into reducers
  yield put({
    type: TabsAction.ADD_ROUTE,
    routes: sortBy(groupedRoutes, route => route.displayName),
  });
}

// export const routeSelector = (state: RootState) => state.entities.actionResult;
export const cvcDataSelector = (state: RootState) => state.entities.clientViewConfigurations;
export const globalActionSelector = (state: RootState) => state.entities.globalActions;
export const pending = (state: RootState) => state.queries.resolveAction && state.queries.resolveAction.isPending;

export function* watchFetchCVCandActions() {
  yield takeLatest(getType(authActions.glideLoginSuccess), fetchCVCandActions);
}

export default [watchFetchCVCandActions];
